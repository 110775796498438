import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Mail from '../../images/Mail.svg'
import Phone from '../../images/Phone.svg'
import Location from '../../images/Location.svg'
import MakerImg from '../../images/Loaction-marker.svg'

const WhereWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(29, 33, 72, 0.0975863);
  border-radius: 8px;
`
const MapWrapper = styled.div`
  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }
  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }
  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }
`
const Where = ({ where }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.initMap = function () {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: {
            lat: where.geopoint.latitude,
            lng: where.geopoint.longitude,
          },
          zoom: 15,
          options: {
            mapTypeControl: false,
            streetViewControl: false,
          },
        })
        const marker = new window.google.maps.Marker({
          position: {
            lat: where.geopoint.latitude,
            lng: where.geopoint.longitude,
          },
          map,
          icon: MakerImg,
        })
      }
    }
  }, [])
  return (
    <div tw="container pt-8 pb-28 text-center">
      <h2 tw="text-navy mb-5">{where.headline.text}</h2>
      <p tw="max-w-screen-sm m-auto text-ocean text-lg mb-10">
        {where.subheadline.text}
      </p>

      <WhereWrapper tw="grid md:grid-cols-3 items-center rounded-lg">
        <div tw="text-left flex justify-center rounded-t-lg md:rounded-l-lg">
          <div tw="py-16 md:py-0">
            <h4 tw="mb-10 text-navy">Our office</h4>
            <div tw="flex flex-col gap-5">
              <p tw="text-navy w-52	flex">
                <img src={Location} tw="w-6 inline mr-3" />
                <span>{where.address}</span>
              </p>
              <p tw="text-navy flex">
                <img src={Phone} tw="w-6 inline mr-3" />
                <span>{where.phone}</span>
              </p>
              <p tw="text-navy flex">
                <img src={Mail} tw="w-6 inline mr-3" />
                <span>{where.email}</span>
              </p>
            </div>
          </div>
        </div>
        <div tw="col-span-2 rounded-b-lg md:rounded-r-lg">
          <MapWrapper>
            <div
              id="map"
              style={{ height: '435px', width: '100%' }}
              tw="rounded-b-lg md:rounded-r-lg"></div>
          </MapWrapper>
        </div>
      </WhereWrapper>
    </div>
  )
}
export default Where
