import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import WatchElementAnimate from '../components/watch-element-animate'
import background from '../images/Hero_Background_Image_03.svg'
import SEO from '../components/SEO/SEO'
import AboutIntro from '../components/about/about-intro'
import WhoWeAre from '../components/about/who-we-are'
import Story from '../components/about/story'
import Values from '../components/about/values'
import Advisors from '../components/about/advisors'
import Where from '../components/about/where'
import LatestNews from '../components/about/latest-news'
import ModalVideo from 'react-modal-video'
import Helmet from 'react-helmet'
import { withPreview } from 'gatsby-source-prismic'
import 'react-modal-video/css/modal-video.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'
import about_us from '../images/about-us.jpg'
// styles
const HeroWrapper = styled.div`
  background:#030406;
  padding: 138px 0 138px 0;
  @media (max-width: 640px) {
    padding: 138px 0 20px 0;
  }
`
const AboutUsWrapper = styled.div`
  margin-top: -2rem;
  @media (max-width: 640px) {
    margin-top: 2rem;
  }
`
const PlayImageWrapper = styled.div`
  margin-top: -238px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 640px) {
    margin-top: -220px;
  }
`
// markup
const AboutPage = ({
  data: { about, resourcesList },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const [isOpen, setOpen] = useState(false)
  let videoId = 'EMu5jiHLsQM'
  if (
    about.data.hero_video.embed_url &&
    '' != about.data.hero_video.embed_url
  ) {
    videoId = about.data.hero_video.embed_url.substring(
      about.data.hero_video.embed_url.lastIndexOf('=') + 1,
      about.data.hero_video.embed_url.length
    )
  }
  return (
    <main>
      <SEO
        title={about.data.meta_title}
        desc={about.data.meta_description}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div tw="relative">
            <div>
              <h1 tw="text-white">
                {about.data.hero_headline.text}
              </h1>
            </div>
            
          </div>
        </div>
      </HeroWrapper>
      <AboutUsWrapper>
        <div tw="container">
          <div tw="grid grid-cols-1 md:grid-cols-2 gap-8 pb-64 items-center justify-between w-full">
                  <div>
                    <img src={about_us} tw="rounded-md"/>
                  </div>
                  <div>
                    <h1 tw="pb-4 break-words">I’m Pengyi</h1>
                    <p>I’m still a solo founder.</p>
                    <p>I’ve been working with some e-commerce startp for ten years, I know not every startup can raise money and bootstrapping is really hard.</p>
                    <p>I help my client spend their money wisely and revenue positive early.</p>
                    <p>I help them choose the correct solution in the very beginning, make IT spending worth every penny.</p>
                    <p>I lived with my family in a coastal city.</p>
                    <p>Contact me if you’re interested to work with me.</p>
                  </div>
          </div>
        </div>
      </AboutUsWrapper>
      {about.data.body.map((b, index) => {
        switch (b.slice_type) {
          case 'intro':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <AboutIntro intro={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'who_we_are':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <WhoWeAre who={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'our_story':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Story story={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'latest_news':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <LatestNews
                    news={b.primary}
                    resourcesList={resourcesList.nodes}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'our_values':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Values values={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'our_advisors':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Advisors advisors={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'where_we_are':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Where where={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          default:
            return null
        }
      })}
    </main>
  )
}

export default withPreview(AboutPage)

export const pageQuery = graphql`
  query AboutQuery($locale: String!) {
    about: prismicAbout(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        hero_headline {
          text
        }
        hero_video {
          type
          embed_url
        }
        video_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_background_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicAboutBodyIntro {
            slice_type
            primary {
              headline {
                text
              }
              intro_description {
                text
              }
            }
          }
          ... on PrismicAboutBodyWhoWeAre {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              linkedin {
                url
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              name
              role
              email
            }
          }
          ... on PrismicAboutBodyOurStory {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              year
              story
            }
          }
          ... on PrismicAboutBodyLatestNews {
            slice_type
            primary {
              headline {
                text
              }
            }
          }
          ... on PrismicAboutBodyOurValues {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              value_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              value
              value_description
            }
          }
          ... on PrismicAboutBodyOurAdvisors {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              email
              name
              role
              linkedin {
                url
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicAboutBodyWhereWeAre {
            slice_type
            primary {
              headline {
                text
              }
              address
              email
              phone
              subheadline {
                text
              }
              map_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              geopoint {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
    resourcesList: allPrismicResource(
      sort: { fields: first_publication_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      nodes {
        data {
          meta_title
          category
          meta_description
          content {
            html
          }
          image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          link {
            url
          }
        }
        first_publication_date
        uid
      }
    }
  }
`
