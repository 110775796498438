import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'

const AboutIntroWrapper = styled.div``

const AboutIntro = ({ intro }) => {
  return (
    <AboutIntroWrapper tw="container pt-12 md:pt-28 pb-8 text-center md:pb-14">
      <div tw="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16">
        <div tw="flex items-center md:text-left">
          <h2 tw="text-navy">{intro.headline.text}</h2>
        </div>
        <div tw="md:hidden w-36 m-auto">
          <hr tw="text-ocean opacity-20	" />
        </div>
        <div tw="md:col-span-2 flex items-center md:text-left md:border-l border-ocean border-opacity-20">
          <p tw="text-xl text-ocean max-w-xl m-auto">
            {intro.intro_description.text}
          </p>
        </div>
      </div>
    </AboutIntroWrapper>
  )
}
export default AboutIntro
